import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

export default function useInventoryItemsList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refAllHistoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'operationType',
      label: 'Action',
      sortable: true,
      thStyle: { width: '15%' },
    },
    {
      key: 'totalItems',
      label: '',
      sortable: false,
      thStyle: { width: '40%' },
    },
    {
      key: 'createdBy',
      label: 'By',
      sortable: false,
      thStyle: { width: '10%' },
    },
    {
      key: 'date',
      label: 'Date',
      sortable: true,
      thStyle: { width: '15%' },
    },
    {
      key: 'fromStore',
      label: 'Store',
      sortable: false,
      thStyle: { width: '10%' },
    },
    {
      key: 'totalQuantity',
      label: 'Quantity',
      sortable: true,
      thStyle: { width: '10%' },
    },
  ]

  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalStockHistory = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'date')
  const isSortDirDesc = ref(route.query.sortDesc ? (route.query.sortDesc === 'true') : true)
  const dateFilter = ref(route.query.date || '')
  const storeFilter = ref([])
  const historyDataObj = ref([])
  if (Array.isArray(route.query.store)) {
    storeFilter.value = route.query.store
  } else if (route.query.store) {
    storeFilter.value = [route.query.store]
  }
  const storeTypeFilter = ref([])
  if (Array.isArray(route.query.operationType)) {
    storeTypeFilter.value = route.query.operationType
  } else if (route.query.operationType) {
    storeTypeFilter.value = [route.query.operationType]
  }
  const userFilter = ref([])
  if (Array.isArray(route.query.user)) {
    userFilter.value = route.query.user
  } else if (route.query.user) {
    userFilter.value = [route.query.user]
  }
  const toDeletedID = ref(null)
  const currentDataLength = ref(0)
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refAllHistoryListTable.value ? refAllHistoryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStockHistory.value,
    }
  })

  const refetchData = () => {
    refAllHistoryListTable.value.refresh()
  }

  watch([searchQuery, dateFilter, storeFilter, storeTypeFilter, userFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchStockHistory = (ctx, callback) => {
    store
      .dispatch('app-all-stock-history/fetchStockHistory', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        store: storeFilter.value,
        operationType: storeTypeFilter.value,
        user: userFilter.value,
        date: dateFilter.value,
      },
      { root: true })
      .then(response => {
        const { transactions, total } = response.data
        currentDataLength.value = transactions.length
        // historyDataObj.value = transactions
        callback(transactions)
        totalStockHistory.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              store: storeFilter.value,
              operationType: storeTypeFilter.value,
              user: userFilter.value,
              date: dateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchStockHistoryForDownload = () => {
    store
      .dispatch('app-all-stock-history/fetchStockHistoryForDownload', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        store: storeFilter.value,
        operationType: storeTypeFilter.value,
        user: userFilter.value,
        date: dateFilter.value,
      },
      { root: true })
      .then(response => {
        const { transactions } = response.data
        historyDataObj.value = transactions
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStockHistory,
    fetchStockHistoryForDownload,
    tableColumns,
    perPage,
    currentPage,
    totalStockHistory,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAllHistoryListTable,
    refetchData,

    toDeletedID,
    currentDataLength,

    // Extra Filters
    storeFilter,
    storeTypeFilter,
    userFilter,
    dateFilter,
    historyDataObj,
  }
}
