<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        md="5"
        lg="5"
        sm="12"
        class="mb-2"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              History
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        md="7"
        lg="7"
        sm="12"
        class="mb-1"
      >
        <div
          class="d-flex justify-content-end"
        >
          <div class="white-bg-dp mr-1">
            <b-form-group>
              <b-input-group>
                <flat-pickr
                  id="main-date"
                  v-model="dateFilter"
                  class="form-control"
                  placeholder="Select a date"
                  :config="flatPickrConfig"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="CalendarIcon"
                    class="cursor-pointer"
                    data-toggle
                    size="18"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div>
            <b-button
              variant="success"
              @click="downloadCSV()"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle d-md-block">Export</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                id="dropdown-Team-form"
                ref="filter_dropdown_stock_history"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <history-filter
                  :user-filter.sync="userFilter"
                  :user-options="userOptions"
                  :store-type-filter.sync="storeTypeFilter"
                  :store-type-options="storeTypeOptions"
                  :store-filter.sync="storeFilter"
                  :store-options="storeOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="storeFilter.length || storeTypeFilter.length || userFilter.length || dateFilter">
          <hr class="dividerHR filter-divider">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="dateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="dateFilter = ''"
                  >
                    {{ dateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="storeVal in storeFilter"
                  :key="storeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStoreFilter(storeVal)"
                  >
                    <span class="text-capitalize"> <span class="text-capitalize">{{ resolveStoreName(storeVal) }}</span></span>
                  </b-form-tag>
                </li>
                <li
                  v-for="storeTypeVal in storeTypeFilter"
                  :key="storeTypeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStoreTypeFilter(storeTypeVal)"
                  >
                    <span class="text-capitalize"> <span class="text-capitalize">{{ storeTypeVal }}</span></span>
                  </b-form-tag>
                </li>
                <li
                  v-for="user in userFilter"
                  :key="user"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromUserFilter(user)"
                  >
                    <span class="text-capitalize"> <span class="text-capitalize">{{ resolveUserName(user) }}</span></span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-table
        ref="refAllHistoryListTable"
        class="position-relative min-height-table"
        :items="fetchStockHistory"
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        responsive
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(operationType)="data">
          <div class="d-flex justify-content-start">
            <div class="my-auto">
              <feather-icon
                :icon="resolveStockTypeWiseIcon(data.item.operationType)"
                :class="`${ resolveStockTypeVariant(data.item.operationType) }`"
                style="margin-bottom: 3px;"
                size="20"
              />
            </div>
            <div class="ml-1">
              <b
                v-if="data.item.transfer"
                class="text-black-bold"
              >
                <span class="text-capitalize">{{ resolveStockTypeHyphen(data.item.operationType) }} (via Tx)</span>
                <br>
                <span class="font_normal">{{ data.item.stringID }}</span>
              </b>
              <b
                v-else
                class="text-black-bold text-capitalize"
              >
                {{ resolveStockTypeHyphen(data.item.operationType) }}
                <br>
                <span class="font_normal">{{ data.item.stringID }}</span>
              </b>
            </div>
          </div>
        </template>
        <template #cell(createdBy)="data">
          <span>{{ data.item.createdBy.name }}</span>
        </template>
        <template #cell(totalItems)="row">
          <div
            class="text-left d-flex justify-content-between cursor-pointer"
            @click="row.toggleDetails"
          >
            <div>
              <b-button
                v-model="row.detailsShowing"
                variant="flat-light"
                class="collapse-button"
              >
                <span v-if="row.item.transfer"> {{ row.item.totalItems }} Items (Fr.{{ row.item.referenceStringID }})</span>
                <span v-else-if="row.item.inventoryRequest">
                  {{ row.item.totalItems }} Items (Fr. <b-button
                    v-if="canViewThisAction('show', 'InventoryRequest')"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="link"
                    class="weight-600 align-middle detail-view-id"
                    :to="{ name: 'inventory-inventory-request-view', params: { id: row.item.irReference } }"
                  >
                    {{ row.item.irReferenceStringID }}
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="none"
                    type="button"
                    class="weight-400 align-middle detail-view-id not-button"
                  >
                    {{ row.item.irReferenceStringID }}
                  </b-button>)
                </span>
                <span v-else-if="row.item.purchaseRequest">
                  {{ row.item.totalItems }} Items (Fr. <b-button
                    v-if="canViewThisAction('show', 'PurchaseRequest')"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="link"
                    class="weight-600 align-middle detail-view-id"
                    :to="{ name: 'purchasing-purchase-request-show', params: { id: row.item.prReference } }"
                  >
                    {{ row.item.prReferenceStringID }}
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="none"
                    type="button"
                    class="weight-400 align-middle detail-view-id not-button"
                  >
                    {{ row.item.prReferenceStringID }}
                  </b-button>)
                </span>
                <span v-else> {{ row.item.totalItems }} Items </span>
              </b-button>
            </div>
            <div class="my-auto">
              <span
                v-if="row.detailsShowing"
                class="vs-label cursor-pointer"
              >
                <feather-icon icon="ChevronDownIcon" />
              </span>
              <span
                v-else
                class="vs-label cursor-pointer"
              >
                <feather-icon icon="ChevronUpIcon" />
              </span>
            </div>
          </div>
        </template>
        <template
          #row-details="row"
        >
          <b-table-simple
            v-if="row.item.transactionItems.length"
            style="margin-bottom: -12px;"
            responsive
          >
            <b-tbody>
              <b-tr
                v-for="(stockDetail, index) in row.item.transactionItems"
                :key="index"
              >
                <b-td
                  class="card-bg-color blank-b-simple-td"
                />
                <b-td
                  class="item-details-b-simple-td"
                >
                  <div class="d-flex">
                    <b-img
                      class="img-preview-block mr-1"
                      :src="stockDetail.item.image ? stockDetail.item.image : defaultImage"
                    />
                    <div style="text-align: left;">
                      <p class="text-bold-black m-0">
                        {{ stockDetail.item.name }}
                      </p>
                      <span
                        class="m-0"
                        style="white-space: normal;"
                      >{{ stockDetail.item.sku }} / {{ stockDetail.item.categoryTitle }} / {{ stockDetail.item.brand }}</span>
                    </div>
                  </div>
                </b-td>
                <b-td
                  class="store-b-simple-td"
                >
                  <!-- <div class="item-store-custom-margin">
                    <span
                      v-if="row.item.operationTypeFilter === 'stock-in' || row.item.operationTypeFilter === 'stock-out'"
                    >{{ stockDetail.fromStore.name }}</span>
                    <span v-else />
                  </div> -->
                </b-td>
                <b-td
                  class="transfer-qty-b-simple-td"
                >
                  <div class="transfer-qty-custom-margin">
                    {{ stockDetail.stockBefore }}
                    <feather-icon icon="ArrowRightIcon" />
                    {{ stockDetail.stockAfter }}
                  </div>
                </b-td>
                <b-td
                  class="total-qty-b-simple-td"
                >
                  <!-- eslint-disable -->
                  <div class="qty-custom-margin">
                    <span v-html="resolveStockQuantity(row.item.operationType, stockDetail.adjustedQuantity)" />
                  </div>
                  <!-- eslint-enable -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  class="card-bg-color"
                />
                <b-td colspan="5">
                  <div
                    v-if="row.item.transfer"
                  >
                    {{ row.item.fromStore.name }}
                    <feather-icon icon="ArrowRightIcon" />
                    {{ row.item.toStore.name }}
                  </div>
                  <div
                    v-if="row.item.operationTypeFilter === 'adjustment'"
                  >
                    {{ row.item.fromStore ? row.item.fromStore.name : '-' }}
                  </div>
                  <div><b>Remarks: </b> {{ row.item.remarks }} </div>
                </b-td>
              </b-tr>
            </b-tbody>

          </b-table-simple>
        </template>
        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.date) }},</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>
        <template #cell(fromStore)="data">
          <span v-if="data.item.transfer && data.item.operationType == 'stock-in'">{{ data.item.toStore.name }}</span>
          <span v-else-if="data.item.transfer && data.item.operationType == 'stock-out'">{{ data.item.fromStore.name }}</span>
          <span v-else-if="data.item.fromStore">{{ data.item.fromStore.name }}</span>
        </template>
        <template #cell(totalQuantity)="data">
          <span
            class="text-bold-black"
          >
            <!-- eslint-disable -->
            <span v-html="resolveStockQuantity(data.item.operationType, data.item.totalQuantity)" />
             <!-- eslint-enable -->
          </span>
        </template>
      </b-table>
    </b-card>
    <div
      v-if="totalStockHistory > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalStockHistory"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BTable, BCard, BTableSimple, BTbody, BTr, BTd, BImg, BPagination, BFormTag, BDropdown,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import {
  ref, onUnmounted, computed,
} from '@vue/composition-api'
import { writeFile, utils } from 'xlsx'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import historyStoreModule from './historyStoreModule'
// eslint-disable-next-line import/no-cycle
import useHistoryList from './useHistoryList'
import historyFilter from './HistoryFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BImg,
    BPagination,
    BFormTag,
    BDropdown,

    flatPickr,
    historyFilter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, disableMobile: true,
      },
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      storeTypeOptions: [
        { text: 'Stock In', value: 'stock-in' },
        { text: 'Stock Out', value: 'stock-out' },
        { text: 'Adjustment', value: 'adjustment' },
        { text: 'Transfer', value: 'transfer' },
      ],
      userOptions: [],
      storeOptions: [],
    }
  },

  beforeMount() {
    this.$http.get('directory/teams/respond-with/user-options')
      .then(response => {
        this.userOptions = response.data.userOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('inventory/store/respond-with/name-and-id')
      .then(response => {
        this.storeOptions = response.data.stores
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    async downloadCSV() {
      // await this.fetchStockHistoryForDownload()
      const formData = new FormData()
      formData.append('date', this.dateFilter)
      await this.$http.post('inventory/stock-history/for/download', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const trns = response.data.transactions || []
          const data = []
          trns.forEach(history => {
            // eslint-disable-next-line no-unused-vars
            let type = ''
            if (history.transfer) {
              type = `${this.resolveStockTypeHyphenCapitalize(history.operationType)} (via Tx)`
            } else {
              type = this.resolveStockTypeHyphenCapitalize(history.operationType)
            }

            // eslint-disable-next-line no-unused-vars
            let referenceID = ''
            if (history.transfer) {
              referenceID = history.referenceStringID
            }
            if (history.inventoryRequest) {
              referenceID = history.irReferenceStringID
            }

            if (history.purchaseRequest) {
              referenceID = history.prReferenceStringID
            }

            // eslint-disable-next-line no-unused-vars
            let storeName = ''
            if (history.fromStore) {
              storeName = history.fromStore.name
            }

            if (history.transfer && history.operationType === 'stock-in') {
              storeName = history.toStore.name
            }

            if (history.transfer && history.operationType === 'stock-out') {
              storeName = history.fromStore.name
            }

            if (history.transactionItems.length) {
              history.transactionItems.forEach(tItem => {
                data.push({
                  stringID: history.stringID,
                  action: type,
                  store: storeName,
                  relatedID: referenceID,
                  itemName: tItem.item.name,
                  sku: tItem.item.sku,
                  category: tItem.item.categoryTitle,
                  brand: tItem.item.brand,
                  by: history.createdBy.name,
                  date: this.dateFormat(history.date),
                  time: this.timeFormatFromDateString(history.date),
                  quantity: this.resolveStockQuantityForExcel(history.operationType, tItem.adjustedQuantity),
                  remarks: history.remarks,
                })
              })
            }
          })
          const worksheet = utils.aoa_to_sheet([
            ['Stock History ID', 'Action', 'Store', 'Related ID', 'Item', 'SKU', 'Category', 'Brand', 'By', 'Date', 'Time', 'Qty', 'Remarks'],
          ])
          utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
          worksheet['!cols'] = [{ wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 40 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 12 }, { wch: 10 }, { wch: 5 }, { wch: 40 }]
          const workbook = utils.book_new()
          utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
          writeFile(workbook, `Inventory History ${this.dateFormat(new Date())}.xlsx`, { compression: true })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveStockQuantityForExcel(stockType, stockQty) {
      const type = stockType.toLowerCase()
      // if (type === 'stock-in') {
      //   return `+${stockQty}`
      // }

      if (type === 'stock-out') {
        if (stockQty > 0) {
          return `-${stockQty}`
        }
        return `${stockQty}`
      }

      // if (type === 'adjustment') {
      //   if (stockQty > 0 || stockQty === 0) {
      //     return `+${stockQty}`
      //   }
      //   return `${stockQty}`
      // }

      return `${stockQty}`
    },
    resolveStockTypeHyphen(stockType) {
      if (stockType) {
        return stockType.replace('-', ' ')
      }
      return ''
    },
    resolveStockTypeHyphenCapitalize(type) {
      if (type === 'stock-in') return 'Stock In'
      if (type === 'stock-out') return 'Stock Out'
      if (type === 'adjustment') return 'Adjustment'
      return ''
    },
    resolveStockTypeVariant(stockType) {
      const type = stockType.toLowerCase()
      if (type === 'stock-in') return 'stock-in-status'
      if (type === 'stock-out') return 'stock-out-status'
      if (type === 'adjustment') return 'stock-adjustment-status'
      if (type === 'transfer') return 'stock-transfer-status'
      return 'primary'
    },
    resolveStockTypeWiseIcon(stockType) {
      const type = stockType.toLowerCase()
      if (type === 'stock-in') {
        return 'DownloadIcon'
      }
      if (type === 'stock-out') {
        return 'UploadIcon'
      }
      if (type === 'adjustment') {
        return 'RepeatIcon'
      }
      if (type === 'transfer') {
        return 'CornerUpRightIcon'
      }
      return 'FolderIcon'
    },
    resolveStockTypeWiseColorAndSymbol(stockType, adjustStock) {
      const type = stockType.toLowerCase()
      if (type === 'stock-in') {
        return `<span class="stock-in-status">+${adjustStock}</span>`
      }
      if (type === 'stock-out') {
        return `<span class="stock-out-status">-${adjustStock}</span>`
      }
      return '<span>0</span>'
    },
    resolveStockQuantity(stockType, stockQty) {
      const type = stockType.toLowerCase()
      if (type === 'stock-in') {
        return `<span style="color: #21BB8B !important">+${stockQty}</span>`
      }

      if (type === 'stock-out') {
        if (stockQty > 0 || stockQty === 0) {
          return `<span style="color: #EE1C24 !important">-${stockQty}</span>`
        }
        return `<span style="color: #EE1C24 !important">${stockQty}</span>`
      }

      if (type === 'adjustment') {
        if (stockQty > 0 || stockQty === 0) {
          return `<span style="color: #21BB8B !important">+${stockQty}</span>`
        }
        return `<span style="color: #EE1C24 !important">${stockQty}</span>`
      }

      return `<span>${stockQty}</span>`
    },
    hideFilter() {
      this.$refs.filter_dropdown_stock_history.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_stock_history.show(true)
    },
    removeFromStoreFilter(storeVal) {
      const index = this.storeFilter.indexOf(storeVal)
      if (index > -1) {
        this.storeFilter.splice(index, 1)
      }
    },
    removeFromStoreTypeFilter(storeTypeVal) {
      const index = this.storeTypeFilter.indexOf(storeTypeVal)
      if (index > -1) {
        this.storeTypeFilter.splice(index, 1)
      }
    },
    removeFromUserFilter(user) {
      const findIndex = this.userOptions.find(obj => obj._id === user)
      if (findIndex) {
        this.userFilter.splice(findIndex, 1)
      }
    },
    resolveStoreName(id) {
      const request = this.storeOptions.find(o => o._id === id)
      if (request) {
        return request.name || ''
      }
      return ''
    },
    resolveUserName(id) {
      const request = this.userOptions.find(o => o._id === id)
      if (request) {
        return request.name || ''
      }
      return ''
    },
    clearAllFilters() {
      this.storeFilter = []
      this.storeTypeFilter = []
      this.userFilter = []
      this.dateFilter = []
    },
  },

  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const STOCK_HISTORY_APP_MODULE_NAME = 'app-all-stock-history'

    // Register module
    if (!store.hasModule(STOCK_HISTORY_APP_MODULE_NAME)) store.registerModule(STOCK_HISTORY_APP_MODULE_NAME, historyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STOCK_HISTORY_APP_MODULE_NAME)) store.unregisterModule(STOCK_HISTORY_APP_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchStockHistory,
      fetchStockHistoryForDownload,
      tableColumns,
      historyDataObj,
      perPage,
      currentPage,
      totalStockHistory,
      historyListData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAllHistoryListTable,
      refetchData,

      // UI
      resolveServiceRequestStatusVariant,

      // Extra Filters
      storeFilter,
      storeTypeFilter,
      userFilter,
      dateFilter,
    } = useHistoryList()

    const selectedServiceRequests = ref([])
    const toggleSelectedServiceRequests = serviceRequestId => {
      const serviceRequestIndex = selectedServiceRequests.value.indexOf(serviceRequestId)

      if (serviceRequestIndex === -1) selectedServiceRequests.value.push(serviceRequestId)
      else selectedServiceRequests.value.splice(serviceRequestIndex, 1)
    }
    const selectAllServiceRequestCheckbox = computed(() => historyListData.value.length && (historyListData.value.length === selectedServiceRequests.value.length))
    const isSelectAllServiceRequestCheckboxIndeterminate = computed(() => Boolean(selectedServiceRequests.value.length) && historyListData.value.length !== selectedServiceRequests.value.length)
    const selectAllCheckboxUpdate = val => {
      // eslint-disable-next-line no-underscore-dangle
      selectedServiceRequests.value = val ? historyListData.value.map(flow => flow._id) : []
    }

    if (searchQuery.value) {
      showSearchBar.value = true
    }

    return {

      // Sidebar

      fetchStockHistory,
      fetchStockHistoryForDownload,
      tableColumns,
      historyDataObj,
      perPage,
      currentPage,
      totalStockHistory,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAllHistoryListTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      historyListData,

      // Filter
      canViewThisAction,

      // UI
      resolveServiceRequestStatusVariant,

      // Extra Filters
      storeFilter,
      storeTypeFilter,
      userFilter,
      dateFilter,

      selectAllServiceRequestCheckbox,
      isSelectAllServiceRequestCheckboxIndeterminate,
      selectedServiceRequests,
      toggleSelectedServiceRequests,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style scoped>
.collapse-button{
    color: #333333 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
}
.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
.card-bg-color{
  background: #F5F8FB;
}
.stock-in-status{
   color: #21BB8B !important;
}
.stock-out-status{
   color: #EE1C24 !important;
}
.stock-adjustment-status{
   color: #00CFE8 !important;
}
.stock-transfer-status{
   color: #FF9F43 !important;
}
/* b-simple-table css */
.blank-b-simple-td {
   width: 15%;
  }
.item-details-b-simple-td{
  width: 50%;
  }
.store-b-simple-td {
     width: 15%;
}
.transfer-qty-b-simple-td {
     width: 10%;
}
.total-qty-b-simple-td {
      width: 10%;
}

.item-store-custom-margin {
   margin-left: 8px;
}

.transfer-qty-custom-margin {
   margin-left: 12px;
}

.qty-custom-margin {
   margin-left: 15px;
}

@media (max-width: 767px){
 table tr td.blank-b-simple-td {
   width: 20%;
   padding: 12px 21px !important;
  }
table tr td.item-details-b-simple-td{
  width: 45%;
  padding: 12px 1px !important;
  }
table tr td.store-b-simple-td {
     width: 15%;
     padding: 12px 1px !important;
}
table tr td.transfer-qty-b-simple-td {
     width: 10%;
     padding: 12px 21px !important;
}
table tr td.total-qty-b-simple-td {
      width: 10%;
      padding: 12px 21px !important;
}
.item-store-custom-margin {
   margin-right: -15px;
}
.transfer-qty-custom-margin {
  margin-right: -45px;
}
.qty-custom-margin {
   margin-right: 45px;
 }
}
/* ./ b-simple-table css */
</style>
