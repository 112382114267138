<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Store"
    >
      <b-form-checkbox
        v-for="storeOption in storeOptions"
        :key="storeOption._id"
        v-model="storeFilterValue"
        :value="storeOption._id"
        name="store"
      >
        {{ storeOption.name }}
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      label="Stock Movement Type"
    >
      <b-form-checkbox
        v-for="storeType in storeTypeOptions"
        :key="storeType.value"
        v-model="storeTypeFilterValue"
        :value="storeType.value"
        name="storeType"
      >
        {{ storeType.text }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="User"
      @click.native.stop
    >
      <v-select
        v-model="userFilterValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="userOptions"
        :reduce="user => user._id"
      />
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
  },
  props: {
    storeFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    userFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    storeOptions: {
      type: Array,
      required: true,
    },
    user: {
      type: [Array, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    storeTypeFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    storeTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      storeFilterValue: JSON.parse(JSON.stringify(this.storeFilter)) || [],
      storeTypeFilterValue: JSON.parse(JSON.stringify(this.storeTypeFilter)) || [],
      userFilterValue: JSON.parse(JSON.stringify(this.user)) || [],
    }
  },
  watch: {
    storeFilter: {
      immediate: true,
      handler(val) {
        this.storeFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    storeTypeFilter: {
      immediate: true,
      handler(val) {
        this.storeTypeFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    userFilter: {
      immediate: true,
      handler(val) {
        this.userFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    showAgainDropdown() {
      this.$emit('emitShowFilter')
    },
    applyFilter() {
      this.$emit('update:storeFilter', this.storeFilterValue)
      this.$emit('update:storeTypeFilter', this.storeTypeFilterValue)
      this.$emit('update:userFilter', this.userFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.storeFilterValue = []
      this.storeTypeFilterValue = []
      this.$emit('update:storeFilter', this.storeFilterValue)
      this.$emit('update:storeTypeFilter', this.storeTypeFilterValue)
      this.$emit('update:userFilter', this.userFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
